<template>
  <div>
    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-5-5">
        <div class="d-flex">
          <nav id="breadcrumbs" class="mb-3">
            <ul>
              <li>
                <a href="#"> <i class="uil-home-alt"></i> </a>
              </li>
              <li>
                <router-link :to="{ name: 'company.hashtags.list' }">
                  {{ $t("hashtag.management") }}
                </router-link>
              </li>
              <li>{{ $t("hashtag.edit") }}</li>
            </ul>
          </nav>
        </div>
        <div class="card" v-if="is_loading">
          <default-loading></default-loading>
        </div>
        <template v-else>
          <template v-if="errorMessages">
            <div
              v-for="(error, index) in errorMessages"
              :key="index"
              class="uk-alert-danger"
              uk-alert
            >
              <a class="uk-alert-close" uk-close></a>
              <p style="color: red">
                <i class="uil-exclamation-triangle"></i> {{ error }}
              </p>
            </div>
          </template>
          <div v-if="successMessage" class="uk-alert-success" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p style="color: green">
              <i class="uil-exclamation-triangle"></i> {{ successMessage }}
            </p>
          </div>
          <div class="card mt-4">
            <div class="card-body">
              <h4><i class="uil-gift"></i> {{ $t("hashtag.edit") }}</h4>
              <hr />
              <div class="row">
                <div class="col-xl-9 m-auto">
                  <form action="">
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="name"
                        >{{ $t("hashtag.name") }}
                        <span class="required">*</span></label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          name="name"
                          value=""
                          :placeholder="$t('hashtag.enter_name')"
                          required=""
                          v-model="item.name"
                        />
                      </div>
                    </div>

                    <div class="uk-card-footer float-right pb-0 pr-0">
                      <router-link
                        class="uk-button-default mr-2 uk-button small"
                        :to="{ name: 'company.hashtags.list' }"
                      >
                        {{ $t("general.back") }}
                      </router-link>
                      <button
                        class="uk-button uk-button-primary small"
                        type="button"
                        @click="saveForm"
                      >
                        <i class="icon-feather-arrow-right"></i>
                        {{ $t("general.save") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DataTable from "@/assets/components/dataTable/DataTable";
import store from "@/core/services";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import hashtag_module, {
  BASE_URL,
  MODULE_NAME,
  ERROR,
  ITEM,
  GET_ITEM,
  UPDATE_ITEM,
} from "@/core/services/store/hashtag.module";
export default {
  name: "HashtagEdit",
  components: { DataTable,DefaultLoading },
  data() {
    return {
      is_loading:false,
      successMessage: null,
      errorMessages: [],
      is_status: false,
      is_address: false,
      image_updated: false,
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, hashtag_module);
  },
  computed: {
    item: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEM];
      },
      set(value) {},
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) {},
    },
  },
  methods: {
    getItem() {
      store
        .dispatch(MODULE_NAME + "/" + GET_ITEM, {
          url: BASE_URL + "/" + this.$route.params.id,
        }).then(()=>{

        })
    },
    saveForm() {
      let form = this.item;
      let formData = new FormData();
      this.errorMessages = [];
      this.is_loading = true;
      if (form.name.length === 0) {
        this.errorMessages.push(
          this.$t("validation.required_field").replace(
            ":NAME",
            this.$t("hashtag.name")
          )
        );
      this.is_loading = false;
        return false;
      }

      formData.append("name", form.name);

      store
        .dispatch(MODULE_NAME + "/" + UPDATE_ITEM, {
          url: BASE_URL + "/" + this.$route.params.id + "/update",
          contents: formData,
          returnType: "stateless",
          acceptPromise: true,
        })
        .then(() => {
          if (!this.error) {
            this.successMessage = this.$t("general.successfully_updated");
            this.$router.push({ name: "company.hashtags.list" });
          }else this.errorMessages.push(this.error)
        this.is_loading = false;
        })
    },
    processFile(event) {
      this.image_updated = true
      this.item.image = event.target.files[0];
      let blob = URL.createObjectURL(this.item.image);
      let imageHolder = $(".logoarea");
      imageHolder.attr("src", blob);
    },
    inputClick() {
      $("#avatar").click();
    },
    resetMessages() {
      this.errorMessages = [];
      this.successMessage = null;
    },
  },
  mounted() {
    if(!this.$isGiftVisible){
      this.$router.push({name:'company.dashboard'})
    }
    this.getItem()
  },
  watch: {
    error(){
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>